.navbar-wrapper {
    height: auto !important;
    width: 100%;
    padding: 36px 0 0;
    display: flex;
    justify-content: space-between;
    position: sticky;
    left: 0;
    right: 0;
    top:0;
    z-index: 9;
    background: #fff;
}
.navbar-light{padding:0px !important;}
.logo {
    padding: none;
    margin: none;
    max-width: 268px;
    width: 100%;
    display: inline-block;
}
.logout {
    font-family: Inter !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    margin: 20px;
    text-decoration: none;
    color: #2e3394 !important;
}
.nav-btn {
    background: none;
    border: none;
    padding: 8px;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    color: #2e3394;
}

.custom-close-button{
    flex: 1;    
    display: flex;
    justify-content: flex-end;
    font-size: 24px;
}

.active-tab {
    background-color: blue;
    color: white;
  }

  .mob-logo{display: none;}

  @media only screen and (max-width:767px){
    .navbar-wrapper{
        padding-top: 16px;
    }
    .navbar-nav button{text-align: right; margin-bottom: 32px; padding:16px 0; color: #040F0F; font-weight: 600;}
    .navbar-nav button:last-child{margin-bottom: 0;}
    .navbar-nav{padding-right: 0px !important;}
    .offcanvas{  max-width: 256px;  }
    .offcanvas-header{padding:40px 40px 20px;}
    .offcanvas-body{padding:0 40px;}
    .offcanvas-title{display: none;}
    .custom-toggle-button{font-size: 24px;}
    .nav-back-button{font-size: 24px;}
    .mob-logo{display: block;} 
  }